@import './src/scss/variables';
@import './src/scss/app';

$height: 180px;
$width: 180px;

$innerHeight: 110px;
$innerWidth: 110px;

.my-flex-cont {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-flow: row wrap;
  gap: 2rem;
  padding-bottom: 30px;

  .my-flex-box {
    margin: 0;
    flex: 0 1 auto;


    height: 100%;
    width: $width;
    position: relative;
    display: block;

    .chart {
      display: block;
      text-align: center;
      padding-top: $height / 2 + 0px;
      margin-top: 20px;
      z-index: 1;
      position: relative;
    }

    .active-circle {
      position: absolute;
      width: $width;
      height: $height;
      background: green;
      left: 0;
      border-radius: 50%;

      @for $i from 0 through 100 {
        &.percent-#{$i} {
          $deg: ($i*1.8);

          .arrow {
            transform: rotate(#{$deg}deg);
          }

          &.ping {
            background: conic-gradient(from -90deg, green, green #{$deg}deg, gray #{$deg}deg, gray 180deg, transparent 180deg, transparent 360deg);
            color: green;
          }

          &.jitter {
            background: conic-gradient(from -90deg, yellow, yellow #{$deg}deg, gray #{$deg}deg, gray 180deg, transparent 180deg, transparent 360deg);
            color: yellow;
          }

          &.download {
            background: conic-gradient(from -90deg, orange, orange #{$deg}deg, gray #{$deg}deg, gray 180deg, transparent 180deg, transparent 360deg);
            color: orange;
          }

          &.upload {
            background: conic-gradient(from -90deg, red, red #{$deg}deg, gray #{$deg}deg, gray 180deg, transparent 180deg, transparent 360deg);
            color: red
          }

          //mask-image: conic-gradient(from -90deg, #000000, #000000 #{$deg}deg, #00000036 #{$deg}deg, #00000036 180deg, transparent 180deg, transparent 360deg);
        }
      }

      .arrow {
        width: 50px;
        height: 20px;
        position: absolute;
        left: 50px;
        top: 78px;
        transform-origin: 40px center;
        transition-duration: 0.2s;
        transition-property: transform;
      }
    }

    .inner-circle {
      position: absolute;
      left: ($width - $innerWidth) / 2 + 0px;
      top: ($height - $innerHeight) / 2 + 0px;
      width: $innerWidth;
      height: $innerHeight;
      background: white;
      border-radius: 50%;
      //box-shadow: 0px 0px 31px 0px #333333;
    }
  }
}